import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';

const Profile = lazy(() => import('modules/Users/container/Profile'));

const ProfilePage = () => (
  <DashboardLayout>
    <Helmet title="Profile | FMProcureNet" />
    <Suspense fallback={<Loading />}>
      <Profile />
    </Suspense>
  </DashboardLayout>
);

export default ProfilePage;
