import React from 'react';
import { Helmet } from 'react-helmet';

import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';

import Main from '../privacyPolicy/privacyPolicy.styled';

const TermsPage = () => (
  <DashboardLayout>
    <Helmet title="Terms | FMProcureNet" />
    <Main>
      <Container>
        <div className="content">
          <h1>Terms of Use</h1>
          <p>
            <b>FMProcureNet welcomes you to the platform.</b>
          </p>
          <p>
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these terms. These terms apply to
            all visitors, users and others who access or use the Service.
          </p>
          <p>
            <b>
              By accessing or using the Service you agree to be bound by these
              terms. If you disagree with any part of the terms then you may not
              access the Service.
            </b>
          </p>
          <h2>Accounts</h2>
          <p>
            When you partner with us, you must provide us information that is
            accurate, complete, and updated at all times. If you fail to do so,
            it constitutes a breach of the terms, which may result in the
            service quality. You are solely responsible for securing and
            safeguarding the password that you use to access the service and for
            any activities or actions under your password, whether your password
            is with our service or a third-party service. You agree not to
            disclose your password to any third party. You must notify us
            immediately upon becoming aware of any breach of security or
            unauthorized use of your account.
          </p>
          <h2>Content</h2>
          <p>
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material (&quot;Content&quot;). You are responsible for the
            copyright of all the contents that you upload and share with us. Our
            quality team will immediately take this off our site. We do&nbsp;
            <b>NOT</b>
            &nbsp;allow adult content.&nbsp;
            <b>
              We have a zero-tolerance policy towards content that exploits
              children.
            </b>
          </p>
          <h2>Usage of Services</h2>
          <p>
            If you wish to partner with FMProcureNet, you may be asked to disclose
            certain information specific and relevant to your business
            including, without limitation, your company details, nature of
            business, client details, and references in industry, contact person
            name, phone number and official email address. All this information
            will be used for your profiling in our platform and to ensure much
            needed publicity and attention of our corporate sourcing teams. No
            personal information will be shared without your concern.
          </p>
          <h2>How we use your information</h2>
          <p>
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </p>
          <ul>
            <li>To present your profile to all our sourcing partners</li>
            <li>
              To provide you with information, products or services that you
              request from us.
            </li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>To showcase your information to potential buyers</li>
            <li>
              To notify you about changes to our sites or any products or
              services
            </li>
            <li>
              To allow you to participate in interactive features on our site.
            </li>
            <li>
              Engage in analysis, research and report regarding use of our
              Services.
            </li>
            <li>Protect our service and our users.</li>
          </ul>
          <h2>Links to Other Web Sites</h2>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by FMProcureNet. FMProcureNet has no
            control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third party web sites or
            services. You further acknowledge and agree that FMProcureNet shall not
            be responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with use of
            or reliance on any such content, goods or services available on or
            through any such web sites or services. We strongly advise you to
            read the terms and conditions and privacy policies of any
            third-party web sites or services that you visit.
          </p>
          <h2>Termination</h2>
          <p>
            We may terminate or suspend access to our Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms. All provisions
            of the Terms which by their nature should survive termination shall
            survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity and limitations of
            liability. Upon termination, your right to use the Service will
            immediately cease. If you wish to terminate your account, you may
            simply discontinue using the Service. All provisions of the Terms
            which by their nature should survive termination shall survive
            termination, including, without limitation, ownership provisions,
            warranty disclaimers, indemnity and limitations of liability.
          </p>
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of Maharashtra, India, without regard to its conflict of law
            provisions. Our failure to enforce any right or provision of these
            Terms will not be considered a waiver of those rights. If any
            provision of these Terms is held to be invalid or unenforceable by a
            court, the remaining provisions of these Terms will remain in
            effect. These Terms constitute the entire agreement between us
            regarding our Service, and supersede and replace any prior
            agreements we might have between us regarding the Service.
          </p>
          <h2>Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. By continuing to access or use our Service
            after those revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, please stop
            using the Service.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us
            at&nbsp;
            <a href="mailto:support@fmprocurenet.com">support@fmprocurenet.com.</a>
          </p>
        </div>
      </Container>
    </Main>
  </DashboardLayout>
);

export default TermsPage;
