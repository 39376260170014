import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as clipboard from 'clipboard-polyfill';

import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';
import Icon from 'components/Icon';
import Button from 'components/Button';

import getImage from 'utils/getImage';

import { BriefSection, ContactIconList } from './SupplierDetails.styled';

const Brief = ({
  supplier,
  addToComparision,
  contactHandler,
  isSupplierPage,
}) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [shareMenu, setShareMenu] = useState(false);
  const clientsRef = useRef();

  const resetImages = useCallback(() => {
    if (clientsRef.current) {
      Array.from(clientsRef.current.firstChild.children).forEach((i) => {
        i.style.transition = `transform 0s linear, opacity 0s`;
        i.style.transform = 'translateX(0)';
        i.style.opacity = '1';
      });
      Array.from(clientsRef.current.firstChild.children).forEach((i) => {
        i.style.transition = `transform ${loadedImages *
          2}s linear, opacity 0.2s linear ${loadedImages * 2 - 0.2}s`;
        i.style.opacity = '0';
        i.style.transform = `translateX(-${clientsRef.current.scrollWidth -
          clientsRef.current.getBoundingClientRect().width}px)`;
      });
      setTimeout(resetImages, loadedImages * 2 * 1000);
    }
  }, [loadedImages]);

  function addToComparisionHandler() {
    addToComparision({ id: supplier._id, logo: supplier.company_logo });
  }

  function addLoadedImage() {
    setLoadedImages((state) => state + 1);
  }

  function toggleShareMenu() {
    setShareMenu(!shareMenu);
  }

  function copyToClipboard() {
    clipboard
      .writeText(`${window.location.origin}/s/${supplier._id}`)
      .then(() => setShareMenu(false));
  }

  useEffect(() => {
    setLoadedImages(0);
  }, [supplier]);

  useEffect(() => {
    if (
      clientsRef.current &&
      loadedImages &&
      supplier &&
      supplier.client_logo.length === loadedImages &&
      clientsRef.current.getBoundingClientRect().width <
        clientsRef.current.scrollWidth
    ) {
      Array.from(clientsRef.current.firstChild.children).forEach((i) => {
        i.style.transition = `transform ${loadedImages *
          3}s linear, opacity 0.2s linear ${loadedImages * 3 - 0.2}s`;
        i.style.transform = `translateX(-${clientsRef.current.scrollWidth -
          clientsRef.current.getBoundingClientRect().width}px)`;
        i.style.opacity = '0';
      });
      setTimeout(resetImages, loadedImages * 3 * 1000);
    }
  }, [clientsRef, loadedImages, resetImages, supplier]);

  return (
    <BriefSection>
      <Row>
        <Column style={{ maxWidth: 120 }}>
          <div className="company-logo">
            {supplier.company_logo && (
              <img
                src={getImage(supplier.company_logo)}
                alt={supplier.supplier_name || 'FMProcureNet supplier'}
              />
            )}
          </div>
        </Column>
        <Column flex="3">
          {supplier.supplier_name && <h3>{supplier.supplier_name}</h3>}
          {supplier.company_formation_date && (
            <p>
              {`Since ${dayjs(supplier.company_formation_date).format(
                'MMM D, YYYY',
              )}`}
            </p>
          )}
          <ContactIconList>
            {supplier.fb_link && (
              <li>
                <a
                  href={supplier.fb_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  <Icon name="facebook" verticalAlign="top" fill="#1877F2" />
                </a>
              </li>
            )}
            {supplier.twitter_link && (
              <li>
                <a
                  href={supplier.twitter_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  <Icon name="twitter" verticalAlign="top" fill="#1A91DA" />
                </a>
              </li>
            )}
            {supplier.youtube_link && (
              <li>
                <a
                  href={supplier.youtube_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  <Icon name="youtube" verticalAlign="top" fill="#FF0000" />
                </a>
              </li>
            )}
            {supplier.insta_link && (
              <li>
                <a
                  href={supplier.insta_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  <Icon name="instagram" verticalAlign="top" />
                </a>
              </li>
            )}
            {supplier.linkedin_link && (
              <li>
                <a
                  href={supplier.linkedin_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  <Icon name="linkedin" verticalAlign="top" fill="#0073b1" />
                </a>
              </li>
            )}
            {supplier.website && (
              <li className="is-brief">
                <a
                  href={supplier.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline">
                  {supplier.website}
                </a>
              </li>
            )}
          </ContactIconList>
        </Column>
        <Column>
          {supplier.company_brochure && (
            <div>
              <a
                href={getImage(supplier.company_brochure)}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline">
                <Icon name="download" verticalAlign="middle" />
                Brochure
              </a>
            </div>
          )}
          <div>
            <a href="#!" className="no-underline" onClick={contactHandler}>
              <Icon name="email" verticalAlign="middle" />
              Connect
            </a>
          </div>
          {shareMenu && (
            <Button
              type="button"
              onClick={toggleShareMenu}
              className="close-share-menu"
            />
          )}
          <div style={{ position: 'relative' }}>
            <Button type="button" onClick={toggleShareMenu}>
              <Icon name="share" verticalAlign="middle" />
              Share
            </Button>
            <ul className={`share-menu${shareMenu ? ' is-active' : ''}`}>
              <li>
                <a
                  href={`https://www.facebook.com/sharer.php?u=${window.location.origin}/s/${supplier._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline"
                  style={{ color: '#3b5998' }}>
                  <Icon
                    name="facebook"
                    width="16px"
                    verticalAlign="middle"
                    style={{ fill: '#3b5998' }}
                  />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/intent/tweet?url=${window.location.origin}/s/${supplier._id}&text=Check%20out%20${supplier.supplier_name}%20on%20CoProcur`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline"
                  style={{ color: '#1da1f2' }}>
                  <Icon
                    name="twitter"
                    width="16px"
                    verticalAlign="middle"
                    style={{ fill: '#1da1f2' }}
                  />
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href={encodeURI(
                    `mailto:?subject=${supplier.supplier_name} Details&body=Greetings from FMProcureNet! A unique Platform for ‘Corporate Procurement’. 
Below details for your quick reference.

Name - ${supplier.supplier_name}
Website - ${supplier.website}
Contact details

1. 
Location - ${supplier.contact[0].city}
Email id - ${supplier.contact[0].email}
Contact Number - ${supplier.contact[0].phone}


Please do visit FMProcureNet and keep exploring bigger and better business opportunities. Please write to support@fmprocurenet.com for any assistance.
Regards,
Team FMProcureNet`,
                  )}
                  className="no-underline"
                  style={{ color: '#006ac1' }}>
                  <Icon
                    name="email"
                    width="16px"
                    verticalAlign="middle"
                    style={{ fill: '#006ac1' }}
                  />
                  Email
                </a>
              </li>
              <li>
                <Button
                  type="button"
                  onClick={copyToClipboard}
                  style={{ color: '#006ac1' }}>
                  <Icon
                    name="copy"
                    width="16px"
                    verticalAlign="middle"
                    style={{ fill: '#006ac1' }}
                  />
                  Copy
                </Button>
              </li>
            </ul>
          </div>
          {!isSupplierPage && (
            <div>
              <Button type="button" onClick={addToComparisionHandler}>
                <Icon name="compare" verticalAlign="middle" />
                Compare
              </Button>
            </div>
          )}
        </Column>
      </Row>
      {supplier.client_logo && supplier.client_logo.length > 0 && (
        <>
          <div className="numbers">
            {/* <span>Employee Strength</span>
            <span>{supplier.number_of_employes}</span> */}
            <span>Clients Served</span>
          </div>
          <div className="clients" ref={clientsRef}>
            <div>
              {supplier.client_logo.map((i) => (
                <img
                  key={i}
                  src={getImage(i)}
                  alt="client"
                  onLoad={addLoadedImage}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </BriefSection>
  );
};

Brief.propTypes = {
  supplier: PropTypes.object.isRequired,
  addToComparision: PropTypes.func,
  contactHandler: PropTypes.func.isRequired,
  isSupplierPage: PropTypes.bool,
};

export default Brief;
