import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Loading from 'components/Loading';
import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';
import SupplierDetails from 'components/SupplierDetails';

import api from 'utils/api';

import { toggleContactSupplier } from 'modules/App/store/actions';
import { suppliersSearchSuccess } from 'modules/Listing/store/actions';

const SupplierPage = ({
  match: {
    params: { supplierID },
  },
  psTags,
  dispatch,
}) => {
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(true);

  function contactSupplier(id) {
    dispatch(toggleContactSupplier([id]));
  }

  useEffect(() => {
    axios
      .get(`${api.getSupplier}/${supplierID}`)
      .then(({ data }) => {
        setSupplier(data);
        setLoading(false);
        dispatch(
          suppliersSearchSuccess({
            searchId: '9cc9c999999ccc99999c999c',
            searchKeyword: '',
            searchCities: [],
            searchResults: [data],
          }),
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }, [supplierID, dispatch]);

  return (
    <DashboardLayout>
      <Helmet
        title={supplier ? `${supplier.supplier_name} | FMProcureNet` : 'FMProcureNet'}
      />
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <>
            {supplier ? (
              <SupplierDetails
                supplier={supplier}
                psTags={psTags}
                isSupplierPage
                contactSupplier={contactSupplier}
              />
            ) : (
              <p className="center" style={{ padding: '30vh 0' }}>
                Supplier not found.
              </p>
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

const mapStateToProps = ({
  listing: {
    suppliersSearch,
    searchLoading,
    psTags,
    cities,
    tags,
    supplierConnected,
  },
  user: {
    user: { _id: currentUserID },
  },
}) => ({
  suppliersSearch,
  searchLoading,
  psTags,
  cities,
  tags,
  currentUserID,
  supplierConnected,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

SupplierPage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  psTags: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierPage);
