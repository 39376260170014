import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';
import Row from 'components/Layout/Row';
import Column from 'components/Layout/Column';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SearchForm from 'components/SearchForm';

import getImage from 'utils/getImage';
import { testimonials } from 'utils/contentProvider';

import { suppliersSearchRequest } from 'modules/Listing/store/actions';
import { signUpUpdate } from 'modules/Users/store/actions';

import SupplierImageItem from './SupplierImageItem';

import { Hero, HeroBackground, Services, Updates, FounderProfile } from './home.styled';
import { findByLabelText } from '@testing-library/react';
import { keyframes } from 'styled-components';

const HomePage = ({
  dispatch,
  psTags,
  partners = [],
  cities,
  tags,
  history,
}) => {
  const [testimonial, setTestimonial] = useState(0);
  const [partnerIndex, setPartnerIndex] = useState(1);
  const [heroHeight, setHeroHeight] = useState(-345);

  const heroRef = useRef();  
  // useEffect(()=> {
  //   psTags = psTags.filter(psTag => psTag.display_on_main_page);
  //   console.log("PSTAGS - "  + JSON.stringify(psTags));
  // }); 
  const partnersIntervals = useCallback(() => {
    setPartnerIndex((state) =>
      state === Math.ceil(partners.length / 8) ? 1 : state + 1,
    );
  }, [partners]);

  const resizeHandler = useCallback(() => {
    if (heroRef.current) setHeroHeight(0 - heroRef.current.scrollHeight);
  }, []);

  function previousTestimonial() {
    setTestimonial(testimonial > 0 ? testimonial - 1 : 0);
  }

  function nextTestimonial() {
    setTestimonial(
      testimonial < testimonials.length - 1
        ? testimonial + 1
        : testimonials.length - 1,
    );
  }

  function toggleTestimonial() {
    if (testimonial === testimonials.length - 1) {
      setTestimonial(0);
    } else if (testimonial === 0) {
      setTestimonial(testimonial + 1);
    } else {
      setTestimonial(testimonial + 1);
    }
  }

  useEffect(() => {
    setTimeout(toggleTestimonial, 5000);
  });

  function testimonialClick(e) {
    setTestimonial(Number(e.target.dataset.id));
  }

  function searchHandler(payload) {
    dispatch(suppliersSearchRequest(payload));
    if (history.location.pathname !== '/search') history.push('/search');
  }

  function supplierImageClickHandler(e) {
    dispatch(
      suppliersSearchRequest({
        search_text: e.target.children[0].innerText,
        cities: ['Any'],
      }),
    );
  }

  useEffect(() => {
    dispatch(
      signUpUpdate({
        otpSent: false,
        otpConfirmed: false,
        otpError: null,
        signUpError: null,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(partnersIntervals, 5000);

    return () => clearInterval(interval);
  }, [partnersIntervals]);

  useEffect(() => resizeHandler && resizeHandler(), [resizeHandler]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [resizeHandler]);

  return (
    <DashboardLayout>
      <Helmet title="FMProcureNet" />
      <main>
        <Hero ref={heroRef}>
          <Container>
            <h1 className="center">Find Your Business Partners Here</h1>
            <p className="center">100+ Niche Corporate Services</p>
            <SearchForm
              cities={cities}
              tags={tags}
              searchHandler={searchHandler}
              isHome
            />
          </Container>
        </Hero>
        <HeroBackground
          style={{ marginTop: heroHeight, height: Math.abs(heroHeight) + 125 }}
        />
        <Services serviceImagesLength={psTags.filter(psTag => psTag.display_on_main_page).length}>
          <Container>
            <div className="content">
              <div className="center">
                <h2>100+ services on single platform</h2>
                <h3>5000+ partners across India</h3>
              </div>              
              {/* <div className="marquee-div">
                <marquee className="marquee" scrollamount="5">
                {partners
                  .map((i) => (
                    <img
                      className="partner-images-marquee"
                      key={i._id}
                      src={getImage(i.image_id)}
                      alt={i.partner_name}
                      title={i.partner_name}
                      style={{height:100, width:120, margin:20, objectFit:"contain"}}
                    />
                  ))}
                </marquee>
              </div>              */}
          <div className="marquee-div">
            <ul class="marquee-content">
            {partners
                  .map((i) => (
                    <li>
                    <img
                      className="partner-images-marquee"
                      key={i._id}
                      src={getImage(i.image_id)}
                      alt={i.partner_name}
                      title={i.partner_name}
                      style={{ margin:0, objectFit:"contain", width:150}}
                    />
                    </li>
                  ))}
                  {partners
                  .slice(0,3)
                  .map((i) => (
                    <li>
                    <img
                      className="partner-images-marquee"
                      key={i._id}
                      src={getImage(i.image_id)}
                      alt={i.partner_name}
                      title={i.partner_name}
                      style={{ margin:0, objectFit:"contain", width:150}}
                    />
                    </li>
                  ))}
            </ul>
          </div>
              <div
                className="services-images"
                style={{ animationDuration: `${psTags.length * 0.1}s` }}>
                {psTags.length > 0 &&
                  psTags.filter(psTag => psTag.display_on_main_page).map((i) => (
                    <SupplierImageItem
                      key={i._id}
                      supplier={i}
                      onClick={supplierImageClickHandler}
                    />
                  ))}
              </div>
              {/* <div className="partner-images">
                {partners
                  .slice((partnerIndex - 1) * 8, partnerIndex * 8)
                  .map((i) => (
                    <img
                      key={i._id}
                      src={getImage(i.image_id)}
                      alt={i.partner_name}
                      title={i.partner_name}
                    />
                  ))}
              </div> */}
            </div>
          </Container>
        </Services>
        <Updates>
          <Container>
            <Row className="cards" align="stretch">
              <Column>
                <h4>Customer Testimonials</h4>
                <div className="testimonials">
                  {testimonials.map((i) => (
                    <div
                      key={i.id}
                      style={{
                        transform: `translateX(-${testimonial * 100}%)`,
                      }}>
                      <p className="italic text">{i.content}</p>
                      <p className="user">
                        <img src={i.avatar} alt={i.person} />
                        <span>{i.person}</span>
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="testimonial-dots">
                  <li>
                    <Button
                      type="button"
                      className="testimonial-dots--chevron"
                      disabled={testimonial === 0}
                      onClick={previousTestimonial}>
                      <Icon name="chevron" height="14px" width="14px" />
                    </Button>
                  </li>
                  {testimonials.map((i) => (
                    <li key={i.id}>
                      <Button
                        type="button"
                        className={`testimonial-dot${testimonial === i.id ? ' active' : ''
                          }`}
                        data-id={i.id}
                        onClick={testimonialClick}>
                        <span />
                      </Button>
                    </li>
                  ))}
                  <li>
                    <Button
                      type="button"
                      className="testimonial-dots--chevron"
                      disabled={testimonial === testimonials.length - 1}
                      onClick={nextTestimonial}>
                      <Icon
                        name="chevron"
                        height="14px"
                        width="14px"
                        rotate="180deg"
                      />
                    </Button>
                  </li>
                </ul>
              </Column>
              <Column>

                <h4>Upcoming Event</h4>
                <img style={{ height: 'fit-content' }} src="/assets/img/upcoming_event_fmprocure.jpg" alt="upcoming event" />
                <p className="italic">
                FM, CRE & PROCUREMENT LEADESHIP SUMMIT 2024 &nbsp;
                  <a
                    href="https://www.fmglobalnet.in"
                    target="_blank"
                    rel="noopener noreferrer">
                    Link
                  </a>
                </p>
                <p className="location">
                  <Icon name="location" height="16px" verticalAlign="middle" />
                  <span>PRIDE PLAZA HOTEL AEROCITY DELHI </span>
                </p>
              </Column>
              <Column>
                <h4>Join 5000+ partners like you</h4>
                <img
                  src="/assets/img/two-person-shake-hands.jpg"
                  alt="two person shake hands"
                />
                <p className="italic" style={{ marginBottom: 34 }}>
                  Join our growing network of partners and connect with 1000s of
                  potential leads at ease.
                </p>
                <Button type="a" to="/sign-up" styled>
                  Join FMProcureNet Network
                </Button>
              </Column>
            </Row>
          </Container>
        </Updates>
      </main>
    </DashboardLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = ({ listing: { cities, tags, psTags, partners } }) => ({
  cities,
  tags,
  psTags,
  partners,
});

HomePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  psTags: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
