import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from 'components/Logo';

import ForgotPasswordForm from 'modules/Users/container/ForgotPasswordForm';

import { Page } from './forgotPassword.styled';

const ForgotPasswordPage = () => (
  <Page className="center">
    <Helmet title="Forgot Password | FMProcureNet" />
    <div className="content">
      <Link to="/" className="no-underline">
        <Logo isDark />
      </Link>
      <h1>Forgot your password?</h1>
      <div className="form-container">
        <ForgotPasswordForm />
      </div>
    </div>
  </Page>
);

export default ForgotPasswordPage;
