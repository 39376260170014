import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
// import isNumeric from 'validator/lib/isNumeric';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import DashboardLayout from 'components/Layout/DashboardLayout';
import Container from 'components/Layout/Container';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { supportRequest } from 'modules/Dashboard/store/actions';

import { Banner, FormSection } from './contact.styled';

const ContactPage = ({
  dispatch,
  supportRequestSuccess,
  user,
  supportRequestError,
}) => {
  const [input, setInput] = useState({
    fname: '',
    email: '',
    mobile: '',
    cname: '',
    message: '',
  });
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  function validateFields() {
    const newErrors = [];

    if (!input.fname || input.fname.length < 3)
      newErrors.push({ field: 'fname', error: 'Please enter your name.' });

    if (!input.email || input.email.length < 3 || !isEmail(input.email))
      newErrors.push({
        field: 'email',
        error: 'Please enter a valid email address.',
      });

    /* if (!input.mobile || input.mobile.length !== 10 || !isNumeric(input.mobile))
      newErrors.push({
        field: 'mobile',
        error: 'Please enter a valid 10 digit phone number.',
      }); */

    if (!input.cname || input.cname.length < 3)
      newErrors.push({ field: 'cname', error: 'Please enter company name.' });

    if (!input.message || input.message.length < 3)
      newErrors.push({ field: 'message', error: 'Please enter your message.' });

    setErrors(newErrors);

    return newErrors.length === 0;
  }

  function inputChangeHandler(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }

  function submitHandler(e) {
    e.preventDefault();

    if (validateFields())
      dispatch(
        supportRequest({
          name: input.fname,
          email: input.email,
          phone: input.mobile,
          company: input.cname,
          message: input.message,
        }),
      );
  }

  useEffect(() => {
    if (supportRequestSuccess) {
      setInput({
        fname: '',
        email: '',
        mobile: '',
        cname: '',
        message: '',
      });
      setErrors([]);
      setSuccess(true);
    }
  }, [supportRequestSuccess]);

  useEffect(() => {
    if (user && user._id)
      setInput({
        fname: `${user.user_first_name} ${user.user_last_name}`,
        email: user.user_email,
        mobile: user.user_phone,
        cname: user.user_company,
        message: '',
      });
  }, [user]);

  useEffect(() => {
    if (supportRequestError)
      setErrors((state) => [
        ...state,
        { field: 'response', error: supportRequestError },
      ]);
  }, [supportRequestError]);

  return (
    <DashboardLayout>
      <Helmet title="Contact FMProcureNet" />
      <Banner>
        <Container>
          <h1 className="center">We are here to help you</h1>
        </Container>
      </Banner>
      <FormSection>
        <Container>
          <div className="contact-row">
            <div>
              <ul className="details">
                <li>
                  <Icon name="location" height="16px" width="16px" />
                  <span className="bold">FMProcureNet Pvt. Ltd.</span>
                  <br />
                  <span>
                    Bengaluru
                    <br />
                    support@fmprocurenet.com
                  </span>
                </li>
                <li>
                  <Icon name="call" height="16px" width="16px" />
                  <a href="tel:+919845010012">+91 9845010012</a>
                </li>
                <li>
                  <Icon name="email" height="16px" width="16px" />
                  <a href="mailto:support@fmprocurenet.com">support@fmprocurenet.com</a>
                </li>
              </ul>
              <ul className="social">
                <li>
                  <a href="#!" className="no-underline">
                    <Icon name="facebook" height="16px" width="16px" />
                  </a>
                </li>
                <li>
                  <a href="#!" className="no-underline">
                    <Icon name="twitter" height="16px" width="16px" />
                  </a>
                </li>
                <li>
                  <a href="#!" className="no-underline">
                    <Icon name="instagram" height="16px" width="16px" />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              {success ? (
                <div className="center success-message">
                  <Icon
                    name="tick-done"
                    fill="#13BA58"
                    height="64px"
                    width="64px"
                  />
                  <p>
                    Thanks for reaching out. Our team will get back to you ASAP.
                    Meanwhile you can follow us here
                  </p>
                  <img src="/assets/img/arrow.svg" width="75px" alt="arrow" />
                </div>
              ) : (
                <>
                  <form onSubmit={submitHandler}>
                    <div className="input-row">
                      <input
                        type="text"
                        name="fname"
                        className={
                          errors.some((e) => e.field === 'fname')
                            ? 'has-error'
                            : ''
                        }
                        placeholder="Enter Name"
                        value={input.fname}
                        onChange={inputChangeHandler}
                      />
                      <input
                        type="email"
                        name="email"
                        className={
                          errors.some((e) => e.field === 'email')
                            ? 'has-error'
                            : ''
                        }
                        placeholder="Enter Email"
                        value={input.email}
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <div className="input-row">
                      <input
                        type="text"
                        name="mobile"
                        className={
                          errors.some((e) => e.field === 'mobile')
                            ? 'has-error'
                            : ''
                        }
                        placeholder="Mobile Number"
                        value={input.mobile}
                        onChange={inputChangeHandler}
                      />
                      <input
                        type="text"
                        name="cname"
                        className={
                          errors.some((e) => e.field === 'cname')
                            ? 'has-error'
                            : ''
                        }
                        placeholder="Company Name"
                        value={input.cname}
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <textarea
                      name="message"
                      className={
                        errors.some((e) => e.field === 'message')
                          ? 'has-error'
                          : ''
                      }
                      placeholder="Enter your message"
                      value={input.message}
                      onChange={inputChangeHandler}
                    />
                    <div className="right">
                      <Button type="submit" dark>
                        Send
                      </Button>
                    </div>
                  </form>
                  {errors.length > 0 && (
                    <ul className="errors">
                      {errors.map((e) => (
                        <li key={e.field}>{e.error}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </FormSection>
    </DashboardLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = ({
  dashboard: { supportRequestSuccess, supportRequestError },
  user: { user },
}) => ({
  supportRequestSuccess,
  supportRequestError,
  user,
});

ContactPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  supportRequestSuccess: PropTypes.bool.isRequired,
  supportRequestError: PropTypes.string,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
