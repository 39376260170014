import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'components/Layout/Container';
import Row from 'components/Layout/Row';
import Logo from 'components/Logo';
import Icon from 'components/Icon';

import { socialLinks } from 'utils/contentProvider';

import { Main } from './Footer.styled';

const Footer = () => (
  <Main>
    <Container>
      <Row justify="space-between" align="flex-start" style={{ margin: 0 }}>
        <div className="footer-logo">
          <Link to="/" className="no-underline">
            <Logo />
          </Link>
        </div>
        <div>
          <h5>Company</h5>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div>
          <h5>Legal</h5>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Use</Link>
            </li>
          </ul>
        </div>
        <div>
          <h5>Stay in touch</h5>
          <ul>
            <li>
              <a href="tel:+919845010012">+91 9845010012</a>
            </li>
            <li>
              <a href="mailto:support@fmprocurenet.com">support@fmprocurenet.com</a>
            </li>
          </ul>
        </div>
        <div>
          <h5 className="center">Follow us</h5>
          <ul className="social">
            <li>
              <a
                href={socialLinks.facebook.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="facebook" height="16px" width="16px" />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.twitter.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="twitter" height="16px" width="16px" />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.instagram.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="instagram" height="16px" width="16px" />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.youtube.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="youtube" height="16px" width="16px" />
              </a>
            </li>
            <li>
              <a
                href={socialLinks.linkedin.url}
                target="_blank"
                rel="noopener noreferrer">
                <Icon name="linkedin" height="16px" width="16px" />
              </a>
            </li>
          </ul>
        </div>
      </Row>
    </Container>
  </Main>
);

export default Footer;
